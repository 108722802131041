<template>
  <Loading v-if="processing" />
  <div class="desktop-height relative mx-4">
    <div class="mt-8 mb-3 text-lg text-center">
      Enter your information
    </div>
    <Form @submit="login" class="p-2" :validation-schema="loginvalidationSchema" v-slot="{ errors }">
      <div class="grid grid-cols-3 gap-4 mb-4">
        <div class="col-span-1 mt-1">Email</div>
        <div class="col-span-2">
          <Field type="text" placeholder="Enter email" name="member_name"
            class="p-1 w-full placeholder-white form-control focus:outline-none"
            :class="{ 'is-invalid': errors.member_name }" v-model="loginData.email" />
          <ErrorMessage name="member_name" class="pl-1 block text-left text-red-500 mx-auto text-sm" />
        </div>
      </div>
      <div class="grid grid-cols-3 gap-4 mb-4">
        <div class="col-span-1 mt-1">Password</div>
        <div class="col-span-2">
          <Field type="password" name="password" placeholder="Enter password"
            class="p-1 w-full placeholder-white form-control focus:outline-none"
            :class="{ 'is-invalid': errors.password }" v-model="loginData.password" />
          <ErrorMessage name="password" class="pl-1 block text-left text-red-500 mx-auto text-sm" />
        </div>
      </div>
      <div v-show="loginErrorShow">
        <p class="text-red-500 text-left">{{ loginErrorMessage }}</p>
      </div>
      <div class="mt-8 mb-4 grid grid-cols-5 gap-4 mx-auto">
        <div class="col-start-1 col-end-4" v-if="!verify">
          <button class="font-extrabold" @click="resendVerify" :disabled="processing">
            Resend link
          </button>
        </div>
        <div class="col-end-7 col-span-1">
          <button class="bg-btn rap-btn" :disabled="processing">
            Next
          </button>
        </div>
      </div>
      <div class="text-center mt-12">
        <router-link :to="{ name: 'ForgotPassword' }">
          Forgot password? 
        </router-link>
      </div>
    </Form>
  </div>
</template>

<script>
import { Field, Form, ErrorMessage } from "vee-validate";
import API from "../../api/http";
import * as yup from "yup";
import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import Loading from "../Shared/Loading"

export default {
  name: "Signup",
  components: {
    Field,
    Form,
    ErrorMessage,
    Loading,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    const schema = yup.object().shape({
      member_name: yup
        .string()
        .min(3, "Name lenght must be greater than 2")
        .required("Please enter your email.")
        .typeError("Please enter your email."),
      password: yup
        .string()
        .min(5, "Password lenght must be greater than 5.")
        .required("Please enter password")
        .typeError("Please enter password"),
    }); this.loginData

    return {
      loginData: {
        email: null,
        password: null,
      },
      loginvalidationSchema: schema,
      apiUrl: process.env.VUE_APP_API_BASE_URL,
      loginErrorShow: false,
      loginErrorMessage: null,
      processing: false,
      verify: true,
    };
  },
  methods: {
    profile() {
      this.$router.push({ name: "AddToProfile" });
    },
    signup() {
      this.$router.push({ name: "Signup" });
    },
    login() {
      this.processing = true;
      this.verify = true;
      API.authenticate(this.loginData)
        .then((response) => {
          this.processing = false;
          if (response) {
            if (response.type == "artist") {
              this.$router.push({
                name: "ProfileWithSlug",
                params: { slug: response.slug },
              });
            } else {
              this.$router.push({ name: "TopRappers" });
            }
          } else {
            this.toast.error(response.message[0]);
          }
        })
        .catch((error) => {
          this.processing = false;
          if (error.response.data.verify == false)
            this.verify = error.response.data.verify;
          else {
            this.verify = true;
          }
        });
    },

    resendVerify() {
      this.processing = true;
      API.post('email-resend', { email: this.loginData.email }).then((response) => {
        this.toast.success(response.message);
        this.processing = false;
      }).catch(() => {
        this.processing = false;
      })
    },
  },
};
</script>
